import React, { useMemo } from "react";
import { useTable, useGlobalFilter, useFilters, useSortBy } from "react-table";
import {
  usePagination,
  useRowSelect,
} from "react-table/dist/react-table.development";
import "../../components/dataTable/datatable.css";
import { Checkbox } from "./Checkbox";
import TableLayout from "./TableLayout";

function TableInstance({
  tableData,
  setPageNumber,
  pageNumber,
  columnsRecieved,
  GlobalFilter,
  typeOfTable,
  handleShowObject,
  initialState,
  purchaseTable,
}) {
  const [columns, data] = useMemo(() => {
    const columns = columnsRecieved;
    return [
      columns,
      tableData,
      setPageNumber,
      pageNumber,
      GlobalFilter,
      typeOfTable,
      handleShowObject,
    ];
  }, [tableData]);

  const TableHooks = (hooks) => {
    if (typeOfTable === "project") {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  };
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        filters: initialState ? initialState : [],
        pageSize: typeOfTable === "purchase" ? 999 : 50,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    TableHooks,
  );
  return (
    <>
      <TableLayout
        {...tableInstance}
        storedFilters={initialState?.length > 0 ? true : false}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        GlobalFilter={GlobalFilter}
        typeOfTable={typeOfTable}
        handleShowObject={handleShowObject}
      />
    </>
  );
}

export default TableInstance;
