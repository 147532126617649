import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { IconButton } from "@mui/material";
import GlobalFilter from "../../components/dataTable/dataTableFilters/GlobalFilter";
import { HiSortDescending } from "react-icons/hi";
import { HiSortAscending } from "react-icons/hi";

function TableLayout({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  page,
  prepareRow,
  setPageNumber,
  pageNumber,
  setGlobalFilter,
  state,
  typeOfTable,
  handleShowObject,
  selectedFlatRows,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  gotoPage,
  pageCount,
  setPageSize,
  useSortBy,
  storedFilters,
  setAllFilters,
}) {
  const [showFilters, setShowFilters] = useState(false);
  // NEED TO FIX -----  ----------------------------------------------
  // ON CHECKBOX CLICK FUNCTION FIX ----------------------------------
  useEffect(() => {
    var ListToLocalStorage = buildListOfProjectIds(selectedFlatRows);
    localStorage.setItem("projects", JSON.stringify(ListToLocalStorage));
  }, [selectedFlatRows]);

  function buildListOfProjectIds(selectedFlatRows) {
    const maskedProjectsList = [];
    for (let index = 0; index < selectedFlatRows.length; index++) {
      const element = selectedFlatRows[index].original.id;
      maskedProjectsList.push(element);
    }
    return maskedProjectsList;
  }

  let navigate = useNavigate();
  const routeChange = (id) => {
    switch (typeOfTable) {
      case "project": {
        let path = `/projektoversikt/${id}`;
        navigate(path);
        break;
      }
      case "purchase": {
        let path = `/projektoversikt/inkop/${id}`;
        navigate(path);
        // handleShowObject(id);

        break;
      }
      case "ata": {
        let path = `/projektoversikt/ata/${id}`;
        navigate(path);
        break;
      }
      case "errand": {
        handleShowObject(id);

        break;
      }
      case "inkopsitems": {
        handleShowObject(id);
        break;
      }
      case "supplier": {
        let path = `/register/leverantor/${id}`;
        navigate(path);
        // handleShowObject(id);
        break;
      }
      case "article": {
        let path = `/register/artikel/${id}`;
        navigate(path);
        // handleShowObject(id);
        break;
      }
      case "suppliercategory": {
        handleShowObject(id);
        break;
      }
      case "pricelist": {
        handleShowObject(id);
        break;
      }
      case "module": {
        let path = `/projektoversikt/module/${id}/${0}`;
        navigate(path);
        // handleShowObject(id);
        break;
      }
      case "moduleoverview": {
        let path = `/projektoversikt/module/${id}/${1}`;
        navigate(path);
        // handleShowObject(id); moduleoverview
        break;
      }
      case "staff": {
        let path = `/personal/${id}`;
        navigate(path);
        break;
      }
      case "projectcontactpersonforproject": {
        handleShowObject(id);
        break;
      }

      case "constructionparts": {
        handleShowObject(id);
        break;
      }

      case "account": {
        handleShowObject(id);
        break;
      }

      case "material": {
        handleShowObject(id);
        break;
      }
      case "statusgroup": {
        let path = `/admin/statusgroup/${id}/`;
        navigate(path);
        break;
      }
      case "user": {
        handleShowObject(id);
        break;
      }
      case "municipality": {
        handleShowObject(id);
        break;
      }
      case "suppliercontactperson": {
        handleShowObject(id);
        break;
      }
      case "purchaseoverview": {
        let path = `/projektoversikt/inkop/${id}`;
        navigate(path);
        break;
      }
      default: {
        break;
      }
    }
  };
  const msg = "message";
  const { globalFilter, pageIndex, pageSize } = state;

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <HiSortDescending fontSize={20} style={{ marginLeft: "2px" }} />
      ) : (
        <HiSortAscending fontSize={20} style={{ marginLeft: "2px" }} />
      )
    ) : (
      ""
    );
  };
  const handleRowSingleClick = (event, id) => {
    // Check for middle mouse button or modifier keys
    if (event.button === 1 || event.ctrlKey || event.metaKey) {
      // Define the path based on typeOfTable
      const paths = {
        purchase: `/projektoversikt/inkop/${id}`,
        module: `/projektoversikt/module/${id}/${0}`, // Example path, replace with actual
        project: `/projektoversikt/${id}`, // Example path, replace with actual
      };

      const path = paths[typeOfTable];

      // Open in a new tab if path is defined
      if (path) {
        window.open(path, "_blank").focus();
      }
    }
  };
  return (
    <>
      <GlobalFilter
        filter={globalFilter}
        setFilter={setGlobalFilter}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        setAllFilters={setAllFilters}
        typeOfTable={typeOfTable}
        storedFilters={storedFilters}
      />

      <TableContainer component={Paper}>
        <Table
          size="medium"
          aria-label="simple table"
          className="dataTable"
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => {
              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined }),
                        )}
                      >
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                        <div style={{ display: "flex" }}>
                          {showFilters || storedFilters ? (
                            <>
                              {" "}
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={(e) => handleRowSingleClick(e, row.original.id)}
                  onDoubleClick={() => {
                    routeChange(row.original.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        style={{
                          textAlign:
                            cell.column.id === "selection" ? "center" : "start",
                        }}
                      >
                        {cell.render("Cell", msg)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </Table>
        {(() => {
          return (
            <div className="tablePagination">
              <span>
                {pageIndex + 1} av {pageOptions.length}
              </span>
              <span>
                | Gå till sida:
                <input
                  type="number"
                  min="1"
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                  style={{ width: "30px", border: "none", textAlign: "center" }}
                />
              </span>
              <select
                style={{ border: "none" }}
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100, 500].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Visa {pageSize}
                  </option>
                ))}
              </select>

              <IconButton
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
            </div>
          );
        })()}
      </TableContainer>
    </>
  );
}

export default TableLayout;
