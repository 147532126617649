import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import CloseFonfirmation from "../../../../components/modals/CloseConfirmation";
import UpdateConfirmation from "../../../../components/modals/UpdateConfirmation";
import * as articleAPI from "../../../../api/register/articleApi";
import * as accountAPI from "../../../../api/register/accountApi";
import * as supplierAPI from "../../../../api/register/supplierApi";
import * as moduleAPI from "../../../../api/project/moduleApi";
import * as purchaseAPI from "../../../../api/project/newPurchaseApi";
import * as contactPersonAPI from "../../../../api/register/contactPersonApi";
import * as userAPI from "../../../../api/user/userAPI";
import SendConfirmation from "../../../../components/modals/SendConfirmation";
import Button from "../../../../components/buttons/Button";
import OrderHead from "./components/OrderHeadView";
import ContactReferenceView from "./components/ContactReferenceView";
import AddingArticleView from "./components/AddArticleView";
import OrderRowTableView from "./components/OrderRowTableView";
import ImportOrderRowsView from "./components/ImportOrderRowsView";
import { orderRowsMapping } from "./utils/ImportRows";
import logo from "../../../../assets/gifs/loading.gif";
import { validateOrder, validateOrderRow } from "./utils/Validate";
import { IsObjectEmpty } from "./utils/Validate";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import RestOrderRowsView from "./components/RestOrderRowsView";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import AddStandAloneArticleView from "./components/AddStandAloneArticleView";
import { calculateTotalAmount } from "./utils/RowCalculation";
import AddSupplierContactPersonView from "../../../register/supplier/AddSupplierContactPerson";
import { useCallbackPrompt } from "../../../../hooks/useCallBackPrompt";
import DialogBox from "../../../../components/modals/DialogBox";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
import MoveOrderRowsView from "./components/MoveOrderRowsView";
import ConfirmMoveRows from "./components/ConfirmMoveRows";

function PurchaseForm({
  purchase,
  projectid,
  rows,
  pageNumber,
  setPageNumber,
  numberOfPages,
  alteredRows,
  setAlteredRows,
  setWasSaved,
}) {
  const navigate = useNavigate();
  const [fields, setFields] = useState(purchase);
  const [orders, setOrders] = useState([]);
  const [orderForTransfer, setOrderForTransfer] = useState();
  const [currenctCostForOrder, setCurrentCostForOrder] = useState(
    fields.total_cost,
  );
  useEffect(() => {
    setFields(purchase);
    setCurrentCostForOrder(purchase.total_cost);
    purchaseAPI.getAllOrdersForSelect(projectid).then((orders) => {
      setOrders(orders);
    });
  }, [purchase]);

  const [idForRestOrderCreated, setIdForRestOrderCreated] = useState();
  const [restOrder, setRestOrder] = useState();
  const [supplierToDisplay, setSupplierToDisplay] = useState();
  const [articleToDisplay, setArticleToDisplay] = useState();
  const [priceForChosenArticle, setCurrentPriceForChosenArticle] = useState();
  const [supplierList, setSupplierList] = useState();
  const [accountList, setAccountList] = useState();
  const [moduleList, setModuleList] = useState();
  const [orderFormErrors, setOrderFormErrors] = useState({});
  const [isOrderSubmit, setOrderIsSubmit] = useState(false);
  const [statusImportedRows, setStatusImportedRows] = useState({
    sucess: 0,
    error: 0,
  });
  const [editRowIsOpen, setEditRowIsOpen] = useState(false);
  const [contactPersonList, setContactPersonList] = useState();
  const [isImported, setIsImported] = useState(false);
  const [failedRowsToImport, setFailedRowsToImport] = useState(9);
  const [orderRows, setOrderRows] = useState(rows);
  const [selectedRows, setSelectedRows] = useState([]);
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [blockPurchaseEdit, setBlockPurchaseEdit] = useState();
  const [displaySendConfirmationModal, setDisplaySendConfirmationModal] =
    useState(false);
  const [displayUpdateConfirmationmodal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayImportOrderRowsModal, setDisplayImportOrderRowsModal] =
    useState(false);
  const [displayRestOrderRowsModal, setDisplayRestOrderRowsModal] =
    useState(false);
  const [displayMoveRowsView, setDisplayMoveRowsView] = useState(false);
  const [showAddStandAloneArticleView, setShowAddStandAloneArticleView] =
    useState(false);
  const [
    displayAddSupplierContactPersonView,
    setDisplayAddSupplierContactPersonView,
  ] = useState(false);
  const [displayConfirmMoveRows, setDisplayConfirmMoveRows] = useState(false);
  const [importOrderRowHeads, setImportOrderRowHeads] = useState([]);
  const [dataToMapToOrderRows, setDataToMapToOrderRows] = useState([]);
  const [contactPersons, setContactPersons] = useState();
  const [users, setUsers] = useState();
  const [articleList, setArticleList] = useState();

  const [editRowIndex, setEditRowIndex] = useState(null);
  const [rowsToUpdate, setRowsToUpDate] = useState([]);
  const [saveNewRestRowsToDataBase, setSaveNewRestRowsToDataBase] =
    useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [editRowData, setEditRowData] = useState({
    id: "",
    article: "",
    articlenumber: "",
    articlename: "",
    appendix: "",
    length: "",
    width: "",
    unit: "",
    quantity: "",
    retrieval_cost: "",
    purchase_cost: "",
    purchase_price: "",
    price_unit: "",
    calculation: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [orderRowToAdd, setOrderRowToAdd] = useState();
  const [articleCollapseChecked, setArticleCollapseChecked] = useState(false);
  const [contactViewCollapsed, setContactViewCollapsed] = useState(false);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(hasInputChanged);

  const [articleOnRowBeingEdited, setArticleOnRowBeingEdited] = useState();
  const [sendingEmail, setSendingEmail] = useState(false);
  const { auth } = useAuthContext();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (supplierToDisplay) {
      fields.order_mail = supplierToDisplay.order_mail;
    }
  }, [supplierToDisplay, fields]);

  const updateRowsAfterCreatedRestOrder = useMutation(
    (rew) => {
      console.log("TRIGGERED");
      setSaveNewRestRowsToDataBase(false);
      purchaseAPI.updateMultipleRows(rew);
    },
    {
      onSuccess: (e) => {
        let timer = setInterval(() => {
          window.location = `/projektoversikt/inkop/${idForRestOrderCreated}`;

          clearInterval(timer);
        }, 1500);
      },
      onSettled: () => {
        setSaveNewRestRowsToDataBase(false);
      },
      onError: () => {
        console.log("error fron mutate");
      },
    },
  );

  const testFunction = () => {
    let orderToUpdate = fields;
    orderToUpdate.total_cost = currenctCostForOrder;
    orderToUpdate.orderrows = rowsToUpdate;
    orderToUpdate.orderrows.forEach((element) => {
      element.rested_order = idForRestOrderCreated;
    });
    return orderToUpdate;
  };

  useEffect(() => {
    async function fetchData() {
      if (saveNewRestRowsToDataBase) {
        const result = await testFunction();
        console.log(result, "HEYO");
        if (result) updateRowsAfterCreatedRestOrder.mutate(result);
      }
    }

    fetchData();
  }, [
    saveNewRestRowsToDataBase,
    currenctCostForOrder,
    fields,
    idForRestOrderCreated,
    rowsToUpdate,
    updateRowsAfterCreatedRestOrder,
  ]);
  useEffect(() => {
    if (fields.status === "Restnoterad") {
      setBlockPurchaseEdit(true);
    } else {
      setBlockPurchaseEdit(false);
    }
  }, [fields]);
  const orderRowObject = {
    article: "",
    articlename: "",
    module: undefined,
    appendix: undefined,
    length: undefined,
    width: undefined,
    unit: "",
    quantity: undefined,
    retrieval_cost: "",
    purchase_cost: "",
    purchase_price: "",
    special_price: "",
    price_unit: "",
  };

  const [orderRow, setOrderRow] = useState(orderRowObject);

  // USEEFFECT ARREA ↓↓↓
  // USEEFFECT ARREA ↓↓↓
  // USEEFFECT ARREA ↓↓↓
  useEffect(() => {
    if (fields.supplierId) {
      supplierAPI
        .getSupplier(fields.supplierId)
        .then((supplier) => setSupplierToDisplay(supplier));
      contactPersonAPI
        .getAllContactPersonForSupplier(fields.supplierId)
        .then((contacts) => {
          setContactPersonList(contacts);
        });
    }
  }, [fields.supplierId, displayAddSupplierContactPersonView]);

  useEffect(() => {
    userAPI.userList().then((users) => setUsers(users));
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSupplierList(suppliers));
    accountAPI
      .getAllAccountNames()
      .then((accounts) => setAccountList(accounts));

    moduleAPI
      .getAllForProject(projectid)
      .then((modules) => setModuleList(modules));
  }, [projectid]);

  useEffect(() => {
    setOrderRows(rows);
  }, [rows]);

  useEffect(() => {
    articleAPI
      .getAllArticleNamesWithSpecificSupplierPRicelist(fields.supplierId)
      .then((articles) => setArticleList(articles));
  }, [supplierToDisplay, fields.supplierId]);

  useEffect(() => {
    let listofContacts = [];
    if (contactPersonList) {
      contactPersonList.map((person) => {
        let object = {
          value:
            "Namn: " +
            person.name +
            ". Email: " +
            person.email +
            ". Telefon: " +
            person.phone +
            ". Roll: " +
            person.role,
          label:
            "Namn: " +
            person.name +
            ". Email: " +
            person.email +
            ". Telefon: " +
            person.phone +
            ". Roll: " +
            person.role,
          id: person?.id,
          name: person?.name,
          email: person?.email,
          role: person?.role,
          phone: person?.phone,
        };
        listofContacts.push(object);
      });
      setContactPersons(listofContacts);
    }
  }, [contactPersonList]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setOrderRows((orderRows) => [...orderRows, orderRowToAdd]);
      setOrderRow({
        article: undefined,
        articlename: "",
        module: undefined,
        appendix: "",
        length: "",
        width: "",
        unit: "",
        quantity: "",
        retrieval_cost: "",
        purchase_cost: "",
        purchase_price: "",
        price_unit: "",
        special_price: "",
      });
      setArticleToDisplay(undefined);
      // setKeepDataInForm(true);
      setHasInputChanged(true);
    }
  }, [formErrors, isSubmit, orderRowToAdd]);
  useEffect(() => {
    if (alteredRows.length > 1) {
      setFields({ ...fields, orderrows: alteredRows });
    } else {
      setFields({ ...fields, orderrows: orderRows });
      // setOrderRows([]);
    }
  }, [orderRows, alteredRows]);

  useEffect(() => {
    setRestOrder(fields);
  }, [selectedRows]);

  useEffect(() => {
    if (Object.keys(orderFormErrors).length === 0 && isOrderSubmit) {
      fields.total_cost = currenctCostForOrder;
      console.log(fields);
      mutate(fields);
      setHasInputChanged(false);
    }
  }, [orderFormErrors]);

  useEffect(() => {
    if (editRowIndex === 0 || editRowIndex) {
      setEditRowIsOpen(true);
    } else {
      setEditRowIsOpen(false);
    }
  }, [editRowIndex]);

  // USEEFFECT ARREA ↑↑↑
  // USEEFFECT ARREA ↑↑↑
  // USEEFFECT ARREA ↑↑↑

  // handleSelect AREA ↓↓↓
  // handleSelect AREA ↓↓↓
  // handleSelect AREA ↓↓↓

  const handleSelectSupplier = (e, value) => {
    setFields({ ...fields, supplierId: value.value });
    setContactPersons([
      { value: "", label: "" },
      { value: "", label: "" },
      { value: "", label: "" },
      { value: "", label: "" },
    ]);
    setHasInputChanged(true);
    // setKeepDataInForm(true);
  };

  const handleSelectArticle = (e, value) => {
    articleAPI
      .getArticle(value.value)
      .then((article) => setArticleToDisplay(article));
    setCurrentPriceForChosenArticle(value);
    setOrderRow({ ...orderRow, article: value.value });
  };

  const handleSelectContactPerson = (e, value) => {
    setFields({ ...fields, contactperson: value.id });
    setHasInputChanged(true);
  };

  const handleSelectStatus = (e, value) => {
    setFields({ ...fields, status: value.value });
    setHasInputChanged(true);
  };

  const handleSelectAccount = (e, value) => {
    setFields({ ...fields, account: value.value });
    setHasInputChanged(true);
  };

  const handleSelectModule = (e, value) => {
    setOrderRow({ ...orderRow, module: value.value });
  };

  const handleSelectModuleOnOrderRow = (ev, meta) => {
    const moduleRows = [...orderRows];
    moduleRows[meta.name].module = ev.value;
    setOrderRows(moduleRows);
  };

  // handleSelect AREA ↑↑↑
  // handleSelect AREA ↑↑↑
  // handleSelect AREA ↑↑↑

  // Handle Changes AREA ↓↓↓
  // Handle Changes AREA ↓↓↓
  // Handle Changes AREA ↓↓↓

  const handleChange = (event) => {
    let { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      value = checked ? 1 : 0;
    }

    if (name === "supplier" || name === "articleId") {
      value = parseInt(value);
    }
    setFields({
      ...fields,
      [name]: typeof value === "string" ? value.replace(",", ".") : value,
    });
    setHasInputChanged(true);
  };

  const handleChangeOrderRow = (event) => {
    let { name, value } = event.target;
    if (name === "quantity") {
      value = parseInt(value);
    }
    setOrderRow({
      ...orderRow,
      [name]: typeof value === "string" ? value.replace(",", ".") : value,
    });
  };

  const handleEditRowChange = (event) => {
    event.preventDefault();

    let { name, value } = event.target;
    if (name === "purchase_price") {
    }

    const newRowData = { ...editRowData };

    newRowData[name] =
      typeof value === "string" ? value.replace(",", ".") : value;

    setEditRowData(newRowData);
  };

  const handleAddOrderRow = (e) => {
    e.preventDefault();
    let orderRowToAdd = {
      article: articleToDisplay.id,
      articlename: articleToDisplay.article_name,
      articlenumber: articleToDisplay.article_number,
      module: orderRow.module,
      appendix: orderRow.appendix,
      length: orderRow.length,
      width: orderRow.width,
      unit: articleToDisplay.order_unit,
      order_unit: articleToDisplay.order_unit,
      price_unit: articleToDisplay.price_unit,
      quantity: orderRow.quantity,
      retrieval_cost: priceForChosenArticle.pricelist_retrieval_cost,
      purchase_cost: priceForChosenArticle.pricelist_purchase_price,
      purchase_price:
        priceForChosenArticle.pricelist_purchase_price +
        priceForChosenArticle.pricelist_retrieval_cost,
      total_amount: orderRow.quantity * (orderRow.length * orderRow.width),
    };
    if (orderRow.special_price) {
      orderRowToAdd.purchase_price = orderRow.special_price;
    }

    orderRowToAdd.total_amount = calculateTotalAmount(
      orderRowToAdd,
      articleToDisplay,
    );

    if (orderRowToAdd.width === "") {
      orderRowToAdd.width = undefined;
    }
    let newAmountToAdd =
      orderRowToAdd.total_amount * orderRowToAdd.purchase_price;

    setCurrentCostForOrder(Number(currenctCostForOrder) + newAmountToAdd);
    orderRowToAdd.total_amount = Number(orderRowToAdd.total_amount).toFixed(2);
    setFormErrors(validateOrderRow(orderRowToAdd, articleToDisplay));
    setOrderRowToAdd(orderRowToAdd);
    setIsSubmit(true);
    setArticleToDisplay(undefined);

    if (showAddStandAloneArticleView) setShowAddStandAloneArticleView(false);
  };

  const handleRowEditSubmit = async (event, i) => {
    event.preventDefault();
    const newOrderRows = [...orderRows];
    if (editRowData.length === "") {
      editRowData.length = undefined;
    }
    if (editRowData.width === "") {
      editRowData.width = undefined;
    }

    let newTotalAmount = await calculateTotalAmount(
      editRowData,
      articleOnRowBeingEdited,
    );

    const editedRow = {
      id: orderRows[i].id,
      article: orderRows[i].article,
      order: orderRows[i].order,
      articlenumber: editRowData.articlenumber,
      articlename: editRowData.articlename,
      module: orderRows[i].module,
      appendix: editRowData.appendix,
      length: editRowData.length,
      width: editRowData.width,
      order_unit: orderRows[i].order_unit,
      unit: editRowData.unit,
      price_unit: orderRows[i].price_unit,
      quantity: editRowData.quantity,
      retrieval_cost: editRowData.retrieval_cost,
      purchase_cost: editRowData.purchase_cost,
      purchase_price:
        typeof editRowData.purchase_price === "string"
          ? Number(editRowData.purchase_price)
          : editRowData.purchase_price,
      calculation: Number(editRowData.calculation)
        ? typeof editRowData.calculation === "string"
          ? Number(editRowData.calculation)
          : editRowData.calculation
        : Number(orderRows[i].calculation),
      total_amount: newTotalAmount,
    };

    if (orderRow.special_price) {
      orderRowToAdd.purchase_price = Number(orderRow.special_price);
    }
    let articleToGetRulesFrom = await articleAPI.getArticle(editedRow.article);
    // .waste är 15 och behövervara .15 därav
    editedRow.total_amount = await calculateTotalAmount(
      editedRow,
      articleToGetRulesFrom,
    );
    let amountToAddTototalCoSt = newTotalAmount * editRowData.purchase_price;

    let previousCostForThatRow =
      orderRows[i].total_amount * orderRows[i].purchase_price;
    let newCurrentCost =
      currenctCostForOrder - previousCostForThatRow.toFixed(2);

    newCurrentCost += amountToAddTototalCoSt;

    setCurrentCostForOrder(newCurrentCost.toFixed(2));

    if (editedRow.calculation) {
      let newCalc =
        Number(fields.calculation) -
        Number(orderRows[i].calculation) +
        Number(editedRow.calculation);
      setFields({
        ...fields,
        calculation: newCalc,
      });
    }

    newOrderRows[i] = editedRow;
    setHasInputChanged(true);
    setOrderRows(newOrderRows);
    setEditRowIndex(null);
  };

  // const handleAddToRestOrderCheckBox = (e) => {
  //   var updatedOrderRows = [...orderRows];
  //   // var updatedList = [...selectedRows];
  //   if (e.target.checked) {
  //     // setHasInputChanged(true);
  //     let index = e.target.value;
  //     if (e.target.value > 24) {
  //       index = index - 25 * (pageNumber - 1);
  //     }
  //     let object = updatedOrderRows[index];

  //     // let previousCostForThatRow = object.quantity * object.purchase_price;

  //     // innan drar bort kostnaden FÖr raden som restas från orderns kostnad.
  //     // setCurrentCostForOrder(currenctCostForOrder - previousCostForThatRow);

  //     setSelectedRows([...selectedRows, object]);

  //     updatedOrderRows[index].isRested = 1;
  //     setAlteredRows([...alteredRows, updatedOrderRows[index]]);
  //   } else {
  //     // setHasInputChanged(true);

  //     let index = e.target.value;
  //     if (e.target.value > 24) {
  //       index = index - 25 * (pageNumber - 1);
  //     }

  //     let object = updatedOrderRows[index];

  //     // let previousCostForThatRow = object.quantity * object.purchase_price;

  //     // lägger till kostnaden för raden som "unrestades" till orderns kostnad
  //     // setCurrentCostForOrder(
  //     //   Number(currenctCostForOrder) + previousCostForThatRow
  //     // );

  //     var newList = removeMarked(object);

  //     updatedOrderRows[index].isRested = 0;
  //     setAlteredRows(newList);
  //     setSelectedRows(newList);
  //   }
  //   setRestOrder(fields);
  //   setOrderRows(updatedOrderRows);
  // };

  const handleAddToRestOrderCheckBox = (e) => {
    const updatedOrderRows = [...orderRows];
    const index =
      e.target.value > 24
        ? e.target.value - 25 * (pageNumber - 1)
        : e.target.value;
    const object = updatedOrderRows[index];

    if (e.target.checked) {
      // Use destructuring to avoid mutation of state
      const selectedRowsCopy = [...selectedRows, object];
      const alteredRowsCopy = [...alteredRows, { ...object, isRested: 1 }];
      setSelectedRows(selectedRowsCopy);
      setAlteredRows(alteredRowsCopy);
    } else {
      // Use filter to remove the deselected row from both selectedRows and alteredRows
      const newList = selectedRows.filter((row) => row.id !== object.id);
      const alteredRowsCopy = alteredRows.filter((row) => row.id !== object.id);
      setSelectedRows(newList);
      setAlteredRows(alteredRowsCopy);
    }

    // Update the isRested flag in the orderRows array
    updatedOrderRows[index].isRested = e.target.checked ? 1 : 0;

    setRestOrder(fields);
    setOrderRows(updatedOrderRows);
  };

  const removeMarked = (object) => {
    const tempItems = selectedRows.filter((row) => row.id !== object.id);
    return tempItems;
  };

  const handleEditClick = (e, i) => {
    e.preventDefault();
    setEditRowIndex(i);

    const editRowValues = {
      id: orderRows[i].id,
      articlenumber: orderRows[i].articlenumber,
      articlename: orderRows[i].articlename,
      module: orderRows[i].module,
      appendix: orderRows[i].appendix,
      length: orderRows[i].length,
      width: orderRows[i].width,
      unit: orderRows[i].unit,
      quantity: orderRows[i].quantity,
      retrieval_cost: orderRows[i].retrieval_cost,
      purchase_cost: orderRows[i].purchase_cost,
      purchase_price: orderRows[i].purchase_price,
    };
    articleAPI
      .getArticle(orderRows[i].article)
      .then((article) => setArticleOnRowBeingEdited(article));
    setEditRowData(editRowValues);
  };

  const deleteOrderRow = useMutation((id) => purchaseAPI.deleteOrderRow(id));

  const handleDeleteClick = (rowindex, id, closeConfirmation) => {
    deleteOrderRow.mutate(id, {
      onSettled: (e) => {
        setWasSaved(true);
        setHasInputChanged(false);
        closeConfirmation();
      },
      onSuccess: () => {
        setWasSaved(true);
        closeConfirmation();
        toastSuccess("Lyckades radera rad");
      },
      onError: () => {
        toastError("Gick inte att radera");
      },
    });
  };

  // Handle Changes AREA ↑↑↑
  // Handle Changes AREA ↑↑↑
  // Handle Changes AREA ↑↑↑

  // VALIDATE AREA ↓↓↓
  // VALIDATE AREA ↓↓↓
  // VALIDATE AREA ↓↓↓

  // VALIDATE AREA ↑↑↑
  // VALIDATE AREA ↑↑↑
  // VALIDATE AREA ↑↑↑

  // SUBMIT AREA ↓↓↓
  // SUBMIT AREA ↓↓↓
  // SUBMIT AREA ↓↓↓

  const addRestOrder = useMutation(
    (object) => purchaseAPI.newRestOrder(object),
    {
      onSettled: (e) => {
        setIdForRestOrderCreated(e.data.raw.insertId);
        setSaveNewRestRowsToDataBase(true);
        toastSuccess("Restorder skapades!");
      },
      onError: () => {
        toastError("IO Gick inte att skapa restorder!");
        console.log("Error on creating restorder");
      },
    },
  );

  const { mutate } = useMutation(purchaseAPI.updateOrder, {
    onSuccess: (e) => {
      setWasSaved(true);
      queryClient.invalidateQueries();
      console.log("ON SETTLED", e.data.raw.insertId);
      purchaseAPI.createPDF(fields);
      setOrderIsSubmit(false);
      toastSuccess("IO uppdaterades");
      setHasInputChanged(false);
    },
    onError: () => {
      setHasInputChanged(true);
      toastError("IO Gick inte att uppdatera");
    },
  });

  const handleCreateRestOrder = (
    restRows,
    rowsToUpdateToDatabase,
    sumToBeRemovedFromTotalCost,
  ) => {
    setCurrentCostForOrder(currenctCostForOrder - sumToBeRemovedFromTotalCost);
    let rest_order = restOrder;
    rest_order.created_by = auth.user.id;
    rest_order.id = null;
    rest_order.order = fields.order;
    rest_order.orderrows = restRows;
    rest_order.status = "Restnoterad";
    rest_order.comment = fields.order_number;
    rest_order.created_date = new Date().toLocaleDateString("sv-SE");
    rest_order.referens = fields.referens;
    rest_order.parent_order = fields.id;
    rest_order.requested_delivery_date = undefined;
    addRestOrder.mutate(rest_order);
    setRowsToUpDate([...rowsToUpdateToDatabase]);
    setDisplayRestOrderRowsModal(false);
    // setHasInputChanged(true);
  };

  const handleSubmit = (event) => {
    setFields({ ...fields, contract: supplierToDisplay.contract });
    setOrderFormErrors(validateOrder(fields));
    setOrderIsSubmit(true);
    setDisplayUpdateConfirmationModal(false);
  };

  const ref = useRef(null);
  const confirmSave = async (e) => {
    if (!articleCollapseChecked) setArticleCollapseChecked(false);
    e.preventDefault();
    const errors = await validateOrder(fields, editRowIsOpen, false);
    if (hasInputChanged && !errors.order_mail) {
      setDisplayUpdateConfirmationModal(true);
    } else {
      setOrderFormErrors(validateOrder(fields, editRowIsOpen, false));
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const createPreview = async (e) => {
    e.preventDefault();
    await purchaseAPI.createPDF(fields).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  };

  const Sendpdf = useMutation((id) => purchaseAPI.sendOrderPDF(id));

  const queryClient = useQueryClient();

  const handleConfirmSend = (id, additionalPdfs) => {
    setSendingEmail(true);
    try {
      Sendpdf.mutate(
        { id, additionalPdfs },
        {
          onSettled: (e) => {
            setSendingEmail(false);

            queryClient.invalidateQueries(["purchase"]);
            setHasInputChanged(false);
            let timer = setInterval(() => {
              confirmClose();
              clearInterval(timer);
            }, 0);
            setDisplaySendConfirmationModal(false);
          },
          onSuccess: () => {
            toastSuccess("IO Skickades");
            setSendingEmail(false);
            setDisplaySendConfirmationModal(false);
          },
          onError: () => {
            toastError("IO Gick inte att skicka");
            setSendingEmail(false);
            setDisplaySendConfirmationModal(false);
          },
        },
      );
    } catch {
      console.log("error från click on send");
    }
  };

  const moveRows = useMutation((rows) => purchaseAPI.moveRows(rows));
  const confirmRowsToBemoved = () => {
    const objectToSend = {
      orderToSendFromId: fields.id,
      orderToRecieveId: orderForTransfer,
      rows: selectedRows,
    };

    try {
      moveRows.mutate(objectToSend, {
        onSettled: (e) => {
          setWasSaved(true);
          setDisplayConfirmMoveRows(false);
          setDisplayMoveRowsView(false);
        },
        onSuccess: () => {
          toastSuccess("Rader flyttades");
        },
        onError: () => {
          toastError("Gick inte att Flytta rader!");
        },
      });

      setDisplaySendConfirmationModal(false);
    } catch {
      console.log("error från click on MoveRows");
    }
  };
  // SUBMIT AREA ↑↑↑
  // SUBMIT AREA ↑↑↑
  // SUBMIT AREA ↑↑↑

  const handleClose = (e) => {
    e.preventDefault();
    const isMyObjectEmpty = IsObjectEmpty(fields);
    if (!isMyObjectEmpty) {
      if (hasInputChanged) {
        setDisplayConfirmationModal(true);
      } else {
        confirmClose();
      }
    } else if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      confirmClose();
    }
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const hideRestOrderRowModal = () => {
    setDisplayRestOrderRowsModal(false);
  };

  const hideDisplayImportOrderRowsModal = () => {
    setDisplayImportOrderRowsModal(false);
  };

  const hideSendConfirmationModal = () => {
    setDisplaySendConfirmationModal(false);
  };

  const confirmSend = () => {
    setDisplaySendConfirmationModal(true);
  };

  // IMPORTERA ORDERRADER

  // Behöver dessa för att ha custom button på input type=file
  const hiddenFileInput = React.useRef(null);
  const handleClickOnUpload = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (event) => {
    setIsImported(false);

    const fileUploaded = event.target.files[0];
    const data = await fileUploaded.arrayBuffer();
    const workbook = XLSX.read(data);

    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet, {
      header: 1,
      defval: "",
    });

    setImportOrderRowHeads(jsonData[0]);
    setDataToMapToOrderRows(jsonData);

    if (fileUploaded) setDisplayImportOrderRowsModal(true);
  };

  const ImportRows = async (rowHeadMaps) => {
    let rowsAfterBeingMappedFromImport = await orderRowsMapping(
      dataToMapToOrderRows,
      rowHeadMaps,
      fields.supplierId,
      false,
      fields.project,
    );
    if (rowsAfterBeingMappedFromImport) {
      setIsImported(true);
      let newTotal =
        rowsAfterBeingMappedFromImport.amountToAdd + Number(fields.total_cost);
      setFields({ ...fields, total_cost: newTotal });
    }
    let tempItems = [...orderRows];
    tempItems.push(...rowsAfterBeingMappedFromImport.Rows);
    setOrderRows([...tempItems]);
    setStatusImportedRows({
      success: rowsAfterBeingMappedFromImport.successFullRowsImported,
      error:
        rowsAfterBeingMappedFromImport.failedRows.length +
        rowsAfterBeingMappedFromImport.Rows.length,
    });
    setCurrentCostForOrder(
      Number(currenctCostForOrder) +
        Number(rowsAfterBeingMappedFromImport.amountToAdd),
    );
    setFailedRowsToImport(rowsAfterBeingMappedFromImport.failedRows);
    setHasInputChanged(true);
  };

  // EXPORT MISSLYCKADE ORDERRADER
  const handleOnExport = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(failedRowsToImport);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "misslyckade.xlsx");
    setDisplayImportOrderRowsModal(false);
  };

  const confirmClose = () => {
    navigate(-1);
  };
  // EXPORT MISSLYCKADE ORDERRADER

  ////// import bilagor
  if (
    fields &&
    supplierList &&
    accountList &&
    moduleList &&
    contactPersons &&
    users
  ) {
    return (
      <>
        <form onSubmit={(e) => confirmSave(e)}>
          <DialogBox
            message={`Du har osparad data på inköpsordern`}
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <div ref={ref}>
            <OrderHead
              fields={fields}
              setFields={setFields}
              handleChange={handleChange}
              handleSelectSupplier={handleSelectSupplier}
              handleSelectStatus={handleSelectStatus}
              orderFormErrors={orderFormErrors}
              supplierList={supplierList}
              handleSelectAccount={handleSelectAccount}
              accountList={accountList}
              blockPurchaseEdit={blockPurchaseEdit}
              supplierToDisplay={supplierToDisplay}
              formErrors={formErrors}
              setHasInputChanged={setHasInputChanged}
            />
          </div>

          <ContactReferenceView
            users={users}
            fields={fields}
            handleChange={handleChange}
            setDisplayAddSupplierContactPersonView={
              setDisplayAddSupplierContactPersonView
            }
            handleSelectContactPerson={handleSelectContactPerson}
            supplierToDisplay={supplierToDisplay}
            contactPersons={contactPersons}
            blockPurchaseEdit={blockPurchaseEdit}
            setFields={setFields}
            setHasInputChanged={setHasInputChanged}
            userAPI={userAPI}
          />

          <AddingArticleView
            setShowAddStandAloneArticleView={setShowAddStandAloneArticleView}
            articleList={articleList}
            handleSelectArticle={handleSelectArticle}
            articleToDisplay={articleToDisplay}
            priceForChosenArticle={priceForChosenArticle}
            formErrors={formErrors}
            orderRow={orderRow}
            handleChangeOrderRow={handleChangeOrderRow}
            blockPurchaseEdit={blockPurchaseEdit}
            moduleList={moduleList}
            handleSelectModule={handleSelectModule}
            handleAddOrderRow={handleAddOrderRow}
            handleAddToRestOrderCheckBox={handleAddToRestOrderCheckBox}
            orderStatus={fields.status}
          />
          <>
            <hr></hr>

            {/* <div>
              <Button
                disabled
                startIcon={<UploadFileIcon />}
                onClick={handleClickOnUpload}
              >
                Importera orderrader
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                value=""
              />
            </div> */}
          </>
          <OrderRowTableView
            billed={fields.invoiced_amount}
            calculationCost={fields.calculation}
            orderRows={orderRows}
            handleSelectModuleOnOrderRow={handleSelectModuleOnOrderRow}
            moduleList={moduleList}
            editRowData={editRowData}
            handleEditRowChange={handleEditRowChange}
            handleRowEditSubmit={handleRowEditSubmit}
            setEditRowIndex={setEditRowIndex}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            blockPurchaseEdit={blockPurchaseEdit}
            currenctCostForOrder={currenctCostForOrder}
            editRowIndex={editRowIndex}
            handleCreateRestOrder={setDisplayRestOrderRowsModal}
            canRest={true}
            canImport={true}
            handleAddToRestOrderCheckBox={handleAddToRestOrderCheckBox}
            setDisplayMoveRowsView={setDisplayMoveRowsView}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            numberOfPages={numberOfPages}
            rowsSelected={selectedRows.length > 0}
          />
          <div style={{ display: "flex", gap: "1em", marginTop: "1.5em" }}>
            <Button onClick={(e) => handleClose(e)}>Stäng</Button>
            <Button
              type="submit"
              onClick={() => setArticleCollapseChecked(false)}
              color="success"
              disabled={!hasInputChanged || !fields.account}
            >
              {" "}
              Spara
            </Button>
            <Button
              onClick={(e) => createPreview(e)}
              disabled={hasInputChanged || !fields.account}
            >
              Förhandsgranska
            </Button>
            <Button
              onClick={confirmSend}
              disabled={
                hasInputChanged ||
                fields.status === "Beställning" ||
                fields.status === "Importerad" ||
                !supplierToDisplay?.order_mail ||
                !fields.contactperson
              }
            >
              Skicka order
            </Button>
          </div>
        </form>
        <UpdateConfirmation
          showModal={displayUpdateConfirmationmodal}
          confirmModal={handleSubmit}
          hideModal={() => setDisplayUpdateConfirmationModal(false)}
        />
        <ConfirmMoveRows
          showModal={displayConfirmMoveRows}
          confirmModal={() => confirmRowsToBemoved()}
          fromOrder={fields.order_number}
          ToOrder={orders.find((item) => item.value === orderForTransfer)}
          NumberOfRows={selectedRows.length}
          hideModal={() => setDisplayConfirmMoveRows(false)}
        />
        <CloseFonfirmation
          showModal={displayConfirmationModal}
          confirmModal={confirmClose}
          hideModal={hideConfirmationModal}
        />
        <SendConfirmation
          orderid={fields.id}
          showModal={displaySendConfirmationModal}
          confirmModal={handleConfirmSend}
          sendingEmail={sendingEmail}
          hideModal={hideSendConfirmationModal}
        />
        <ImportOrderRowsView
          showModal={displayImportOrderRowsModal}
          confirmModal={ImportRows}
          hideModal={hideDisplayImportOrderRowsModal}
          orderHeads={importOrderRowHeads}
          statusImportedRows={statusImportedRows}
          isImported={isImported}
          handleOnExport={handleOnExport}
        />
        <RestOrderRowsView
          showModal={displayRestOrderRowsModal}
          hideModal={hideRestOrderRowModal}
          confirmModal={handleCreateRestOrder}
          restedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          moduleList={moduleList}
        />
        <AddStandAloneArticleView
          articleToDisplay={articleToDisplay}
          moduleList={moduleList}
          handleSelectArticle={handleSelectArticle}
          orderRow={orderRow}
          handleChangeOrderRow={handleChangeOrderRow}
          formErrors={formErrors}
          handleSelectModule={handleSelectModule}
          showModal={showAddStandAloneArticleView}
          confirmModal={handleAddOrderRow}
          hideModal={() => setShowAddStandAloneArticleView(false)}
        />
        <AddSupplierContactPersonView
          showModal={displayAddSupplierContactPersonView}
          // confirmModal={addContactPersonToSupplier}
          hideModal={() => setDisplayAddSupplierContactPersonView(false)}
          queryClient={queryClient}
          supplierId={fields.supplierId}
        />
        <MoveOrderRowsView
          currentOrder={fields.id}
          selectedRows={selectedRows}
          showModal={displayMoveRowsView}
          confirmModal={setDisplayConfirmMoveRows}
          hideModal={setDisplayMoveRowsView}
          orderForTransfer={orderForTransfer}
          setOrderForTransfer={setOrderForTransfer}
          orders={orders}
        />
      </>
    );
  } else {
    return (
      <div
        style={{
          position: "absolute",
          top: "400%",
          left: "40%",
        }}
      >
        <img
          src={logo}
          alt="loading..."
          style={{ height: "200px", width: "200px" }}
        />
      </div>
    );
  }
}

export default PurchaseForm;
