import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReadOnlyRow from "../../../../../components/dataTable/purchaseRows/ReadOnlyRow";
import EditableRow from "../../../../../components/dataTable/purchaseRows/EditableRow";
import Button from "../../../../../components/buttons/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";

function OrderRowTableView({
  orderRows,
  handleSelectModuleOnOrderRow,
  moduleList,
  editRowData,
  handleEditRowChange,
  handleRowEditSubmit,
  setEditRowIndex,
  handleEditClick,
  handleDeleteClick,
  blockPurchaseEdit,
  currenctCostForOrder,
  editRowIndex,
  handleCreateRestOrder,
  canRest,
  handleAddToRestOrderCheckBox,
  canImport,
  pageNumber,
  setPageNumber,
  numberOfPages,
  calculationCost,
  billed,
  setDisplayMoveRowsView,
  rowsSelected,
}) {
  if (moduleList)
    return (
      <div style={{ margin: "1em 0", overflow: "auto" }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead
              style={{
                position: "sticky",
                top: "0px",
                margin: "0 0 0 0",
                background: "white",
                zIndex: 2,
              }}
            >
              <TableRow>
                <TableCell scope="col">#</TableCell>
                <TableCell scope="col">Artikelnr</TableCell>
                <TableCell scope="col">Artikel</TableCell>
                <TableCell scope="col">Antal</TableCell>
                <TableCell scope="col">Längd</TableCell>
                <TableCell scope="col">Bredd</TableCell>
                <TableCell scope="col">Mängd ink spill</TableCell>
                <TableCell scope="col">Pris per beställningsenhet</TableCell>
                <TableCell scope="col">Summa</TableCell>
                <TableCell scope="col">Kalkyl</TableCell>
                <TableCell scope="col">Kommentar</TableCell>
                <TableCell scope="col">Modul</TableCell>
                {canRest ? (
                  <>
                    <TableCell colSpan={4} scope="col" align="right">
                      <Button
                        disabled={!rowsSelected}
                        sx={{ marginRight: "1em" }}
                        onClick={(e) => setDisplayMoveRowsView(true)}
                      >
                        Flytta
                      </Button>

                      <Tooltip
                        title={<div style={{ fontSize: "0.85rem" }}>Tillfälligt avstängd</div>}
                      >
                        <div>
                          <Button disabled={true} onClick={(e) => handleCreateRestOrder(true)}>
                            restorder
                          </Button>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}
                {canRest ? (
                  <></>
                ) : (
                  <>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderRows.length > 0 ? (
                <>
                  {orderRows.map((item, i) => (
                    <Fragment key={i}>
                      {editRowIndex === i ? (
                        <EditableRow
                          item={item}
                          i={i + 25 * (pageNumber - 1)}
                          handleSelectModuleOnOrderRow={handleSelectModuleOnOrderRow}
                          indexInRows={i}
                          moduleList={moduleList}
                          editRowData={editRowData}
                          handleEditRowChange={handleEditRowChange}
                          handleRowEditSubmit={handleRowEditSubmit}
                          setEditRowIndex={setEditRowIndex}
                        />
                      ) : (
                        <ReadOnlyRow
                          item={item}
                          i={i + 25 * (pageNumber - 1)}
                          setEditRowIndex={setEditRowIndex}
                          moduleList={moduleList}
                          indexInRows={i}
                          handleEditClick={handleEditClick}
                          isRestAble={canRest}
                          handleAddToRestOrderCheckBox={handleAddToRestOrderCheckBox}
                          handleDeleteClick={handleDeleteClick}
                          blockPurchaseEdit={blockPurchaseEdit}
                        />
                      )}
                    </Fragment>
                  ))}
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em",
            }}
          >
            <div style={{ position: "sticky" }}>
              {pageNumber} / {numberOfPages}
              <IconButton onClick={() => setPageNumber(1)} disabled={pageNumber === 1}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => setPageNumber((page) => page - 1)}
                disabled={pageNumber === 1}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => setPageNumber((page) => page + 1)}
                disabled={pageNumber === numberOfPages}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton
                onClick={() => setPageNumber(numberOfPages)}
                disabled={pageNumber === numberOfPages}
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
            </div>
            <div>
              {currenctCostForOrder || calculationCost ? (
                <>
                  <div
                    style={{
                      textAlign: "end",
                      fontWeight: "600",
                      fontSize: "1.2em",
                      color: "rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    Kalkylkostnad: {calculationCost ? Number(calculationCost).toLocaleString("sv-SE") : 0} kr
                  </div>
                  <div
                    style={{
                      textAlign: "end",
                      fontWeight: "600",
                      fontSize: "1.2em",
                      color: "rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    Total kostnad:{" "}
                    {currenctCostForOrder ? Number(currenctCostForOrder).toLocaleString("sv-SE") : 0} kr
                  </div>
                  {billed ? (
                    <>
                      <div
                        style={{
                          textAlign: "end",
                          fontWeight: "600",
                          fontSize: "1.2em",
                          color: "rgba(0, 0, 0, 0.8)",
                        }}
                      >
                        Fakturerat: {Number(billed).toLocaleString("sv-SE")} kr
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "1.2em",
                  }}
                >
                  Kostnad: 0 kr
                </div>
              )}
            </div>
          </div>
        </TableContainer>
      </div>
    );
}

export default OrderRowTableView;
