import React, { useEffect, useState } from "react";
import CreateOwnFieldValueForm from "./CreateOwnFieldValueForm";
import { useMutation, useQueryClient } from "react-query";
import * as errandStatusAPI from "../../../api/ownFields/errandStatusApi";
import * as errandTypeAPI from "../../../api/ownFields/errandTypeApi";
import * as leadChannelAPI from "../../../api/ownFields/leadChannelApi";
import * as projectPhaseAPi from "../../../api/ownFields/projectPhaseApi";
import * as projectStatusAPI from "../../../api/ownFields/projectStatusApi";
import * as projectTypeAPI from "../../../api/ownFields/projectTypeApi";
import * as timeRegAPI from "../../../api/project/timeRegApi";
import * as progressBarAPI from "../../../api/project/progressBarApi";
import * as projectDeliveryTypeAPI from "../../../api/ownFields/projectDeliveryTypeAPI";
import * as projectIndexTypeAPi from "../../../api/ownFields/projectIndexTypeAPI";
import CurrentListView from "./CurrentListView";
import DeleteConfirmation from "../../../components/modals/DeleteConfirmation";
import UpdateConfirmation from "../../../components/modals/UpdateConfirmation";
import CustomProgressBarView from "../progressBar/CustomProgressBarView";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";

function CreateOwnFieldValueView() {
  const [tableToShow, setTableToShow] = useState();
  // const [whatToDelete, setWhatToDelete] = useState();
  const [whatListToGetFormBackEnd, setWhatListToGetFromBackend] = useState();
  const [wasMutaded, setWasMutated] = useState(false);
  const [displayUpdateConfirm, setDisplayUpdateConfirm] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [objToUpdate, setObjectToUpdate] = useState();
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);
  const [wasEdited, setWasEdited] = useState();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [steps, setSteps] = useState();
  // const [savingAnimation, setSavingSavingAnimation] = useState(false);

  const createErrandStatus = useMutation((staff) =>
    errandStatusAPI.newStatus(staff),
  );
  const createErrandType = useMutation((staff) => errandTypeAPI.newType(staff));
  const createLeadChannel = useMutation((staff) =>
    leadChannelAPI.newLeadChannel(staff),
  );
  const createProjecthPase = useMutation((staff) =>
    projectPhaseAPi.newPhase(staff),
  );
  const createProjectStatus = useMutation((staff) =>
    projectStatusAPI.newStatus(staff),
  );
  const createProjectType = useMutation((staff) =>
    projectTypeAPI.newType(staff),
  );

  const createTimeRegType = useMutation((type) =>
    timeRegAPI.newTimeRegType(type),
  );

  const createProjectDeliveryType = useMutation((type) =>
    projectDeliveryTypeAPI.newDeliveryType(type),
  );

  const createProjectIndexType = useMutation((type) =>
    projectIndexTypeAPi.newIndexType(type),
  );

  const deleteErrandStatus = useMutation((id) =>
    errandStatusAPI.deleteStatus(id),
  );
  const deleteErrandType = useMutation((id) => errandTypeAPI.deletedType(id));
  const deleteLeadChannel = useMutation((id) =>
    leadChannelAPI.deleteLeadChannel(id),
  );
  const deleteProjecthPase = useMutation((id) =>
    projectPhaseAPi.deletePhase(id),
  );
  const deleteProjectStatus = useMutation((id) =>
    projectStatusAPI.deleteStatus(id),
  );
  const deleteProjectType = useMutation((id) => projectTypeAPI.deletedType(id));
  const deleteTimeRegType = useMutation((id) =>
    timeRegAPI.deleteTimeRegType(id),
  );

  const deleteProjectDeliveryType = useMutation((id) =>
    projectDeliveryTypeAPI.deleteDeliveryType(id),
  );

  const deleteProjectIndexType = useMutation((id) =>
    projectIndexTypeAPi.deleteIndexType(id),
  );
  useEffect(() => {
    progressBarAPI.getProgressStep().then((list) => setSteps(list));
  }, []);

  const { mutate } = useMutation(progressBarAPI.updateProgressSteps, {
    onSettled: (e) => {
      setTimeout(function () {
        setShowProgressBar(false);
      }, 1500);
    },
    onSuccess: () => {
      toastSuccess("Statusbar uppdaterad");
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
      console.log("error fron mutate");
    },
  });

  const handleSaveSteps = () => {
    mutate(steps);
  };

  useEffect(() => {
    let deleteField;
    setWasMutated(false);
    setWhatListToGetFromBackend([]);
    switch (tableToShow) {
      case "Projektstatus": {
        deleteField = deleteProjectStatus;

        projectStatusAPI.getStatusList().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Projekttyp": {
        deleteField = deleteProjectType;

        projectTypeAPI.getProjectTypeList().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Projektfas": {
        deleteField = deleteProjecthPase;

        projectPhaseAPi.getPhaseList().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Ärendekategori": {
        deleteField = deleteErrandType;

        errandTypeAPI.getTypeList().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Ärendestatus": {
        deleteField = deleteErrandStatus;

        errandStatusAPI.getStatusList().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Leadskanal": {
        deleteField = deleteLeadChannel;

        leadChannelAPI.getLeadChannelList().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Tidstyp": {
        deleteField = deleteTimeRegType;

        timeRegAPI.getTimeRegTypes().then((list) => {
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Indextyp": {
        deleteField = deleteProjectIndexType;

        projectIndexTypeAPi.getIndexTypeList().then((list) => {
          console.log(list);
          setWhatListToGetFromBackend(list);
        });

        break;
      }
      case "Leveranstyp": {
        deleteField = deleteProjectDeliveryType;

        projectDeliveryTypeAPI.getDeliveryTypeList().then((list) => {
          console.log(list);

          setWhatListToGetFromBackend(list);
        });

        break;
      }
      default: {
        break;
      }
    }
  }, [tableToShow, wasMutaded]);
  let queryClient = useQueryClient();
  const createNewOwnField = (data) => {
    let createField = createErrandStatus;
    setWhatListToGetFromBackend([]);
    switch (data.typeofField) {
      case "Projektstatus": {
        createField = createProjectStatus;
        break;
      }
      case "Projekttyp": {
        createField = createProjectType;

        break;
      }
      case "Projektfas": {
        createField = createProjecthPase;

        break;
      }
      case "Ärendekategori": {
        createField = createErrandType;

        break;
      }
      case "Ärendestatus": {
        createField = createErrandStatus;

        break;
      }
      case "Leadskanal": {
        createField = createLeadChannel;

        break;
      }
      case "Tidstyp": {
        createField = createTimeRegType;

        break;
      }
      case "Indextyp": {
        createField = createProjectIndexType;

        break;
      }
      case "Leveranstyp": {
        createField = createProjectDeliveryType;

        break;
      }
      default: {
        break;
      }
    }
    try {
      createField.mutate(data, {
        onSuccess: (e) => {
          queryClient.invalidateQueries();
          setWasMutated(true);
          toastSuccess("Fält skapades!");
          let timer = setInterval(() => {
            clearInterval(timer);
          }, 3500);
        },
        onError: () => {
          toastError("Gick inte att skapa fält!");
          console.log("error fron mutate");
        },
      });
    } catch {
      console.log("error från click on send");
    }
  };

  const confirmDelete = () => {
    setWasMutated(true);
    switch (tableToShow) {
      case "Projektstatus": {
        deleteProjectStatus.mutate(idToBeDeleted);
        break;
      }
      case "Projekttyp": {
        deleteProjectType.mutate(idToBeDeleted);

        break;
      }
      case "Projektfas": {
        deleteProjecthPase.mutate(idToBeDeleted);

        break;
      }
      case "Ärendekategori": {
        deleteErrandType.mutate(idToBeDeleted);

        break;
      }
      case "Ärendestatus": {
        deleteErrandStatus.mutate(idToBeDeleted);

        break;
      }
      case "Leadskanal": {
        deleteLeadChannel.mutate(idToBeDeleted);

        break;
      }
      case "Tidstyp": {
        deleteTimeRegType.mutate(idToBeDeleted);

        break;
      }
      default: {
        break;
      }
    }
    setDisplayDeleteConfirmation(false);
  };

  const handleDeleteField = (id) => {
    setDisplayDeleteConfirmation(true);
    setIdToBeDeleted(id);
  };

  const handleSubmit = (nameToSave, rowId) => {
    let objToUpdate = {
      id: rowId,
      name: nameToSave,
    };
    setObjectToUpdate(objToUpdate);
    setObjectToUpdate(objToUpdate);
    if (wasEdited) {
      setDisplayUpdateConfirm(true);
    }
  };
  const confirmedUpdate = () => {
    switch (tableToShow) {
      case "Projektstatus": {
        projectStatusAPI.updateStatus(objToUpdate);
        break;
      }
      case "Projekttyp": {
        projectTypeAPI.updatedType(objToUpdate);
        break;
      }
      case "Projektfas": {
        projectPhaseAPi.updatePhase(objToUpdate);

        break;
      }
      case "Ärendekategori": {
        errandTypeAPI.updatedType(objToUpdate);

        break;
      }
      case "Ärendestatus": {
        errandStatusAPI.updateStatus(objToUpdate);

        break;
      }
      case "Leadskanal": {
        leadChannelAPI.updateLeadChannel(objToUpdate);
        break;
      }
      case "Tidstyp": {
        timeRegAPI.updateTimeRegType(objToUpdate);
        break;
      }
      default: {
        break;
      }
    }
    setWasMutated(true);
    setDisplayUpdateConfirm(false);
    setWasEdited(false);
  };
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "2em" }}>
      <div style={{ flex: "1", minWidth: "300px" }}>
        <CreateOwnFieldValueForm
          createNewOwnField={createNewOwnField}
          setTableToShow={setTableToShow}
          tableToShow={tableToShow}
          setShowProgressBar={setShowProgressBar}
        />
      </div>
      <div style={{ flex: "2", minWidth: "600px" }}>
        {whatListToGetFormBackEnd ? (
          <div style={{ display: "grid", gap: "1em" }}>
            <h5>{tableToShow} som finns i systemet</h5>
            <CurrentListView
              handleDeleteField={handleDeleteField}
              whatListToGetFormBackEnd={whatListToGetFormBackEnd}
              handleSubmit={handleSubmit}
              setWasEdited={setWasEdited}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <CustomProgressBarView
        steps={steps}
        setSteps={setSteps}
        handleSaveSteps={handleSaveSteps}
        showModal={showProgressBar}
        confirmModal={"confirmedUpdate"}
        hideModal={() => setShowProgressBar(false)}
      />
      <UpdateConfirmation
        showModal={displayUpdateConfirm}
        confirmModal={confirmedUpdate}
        hideModal={() => setDisplayUpdateConfirm(false)}
      />
      <DeleteConfirmation
        showModal={displayDeleteConfirmation}
        confirmModal={confirmDelete}
        hideModal={() => setDisplayDeleteConfirmation(false)}
        message="fältet?"
      />
    </div>
  );
}

export default CreateOwnFieldValueView;
