import React from "react";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "../../../../../components/buttons/Button";
function ContactReferenceView({
  setFields,
  fields,
  handleChange,
  handleSelectContactPerson,
  supplierToDisplay,
  contactPersons,
  blockPurchaseEdit,
  users,
  setHasInputChanged,
  userAPI,
  setDisplayAddSupplierContactPersonView,
}) {
  const handleChangeOurReference = async (e, value) => {
    await userAPI.getUserInfo(value.value).then((user) => {
      setFields({
        ...fields,
        our_reference: user.name,
        our_reference_mail: user.user_email,
        our_reference_phone: user.user_phonenumber,
      });
    });
    setHasInputChanged(true);
  };

  if (contactPersons && users)
    return (
      <div
        className="wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2em",
          margin: "2em 0",
        }}
      >
        <Box sx={{ marginBottom: "1em" }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontWeight: "medium", marginBottom: "0.5em" }}
          >
            Leveransadress
          </Typography>
          <InputField
            sx={{ width: "100%" }}
            name="purchase_adress"
            label="Leveransadress"
            value={fields.purchase_adress}
            onChange={handleChange}
            disabled={blockPurchaseEdit}
          />
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "2em",
          }}
        >
          <Box sx={{ flex: 1, marginBottom: "1em" }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontWeight: "medium", marginBottom: "0.5em" }}
            >
              Välj kontaktperson hos leverantör
            </Typography>
            <Select
              sx={{ width: "100%" }}
              label="Välj kontaktperson hos leverantör"
              onChange={handleSelectContactPerson}
              options={contactPersons}
              value={
                fields?.contactperson
                  ? contactPersons.find(
                      (item) => item.id === fields.contactperson,
                    )
                  : ""
              }
              disabled={blockPurchaseEdit}
            />
            <Button
              sx={{ marginTop: "15px" }}
              onClick={() => setDisplayAddSupplierContactPersonView(true)}
            >
              Lägg till kontaktperson
            </Button>
          </Box>
          <Box sx={{ flex: 1, marginBottom: "1em" }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontWeight: "medium", marginBottom: "0.5em" }}
            >
              Kontaktperson hos oss
            </Typography>
            <Select
              sx={{ width: "100%" }}
              label="Kontaktperson hos oss"
              onChange={handleChangeOurReference}
              options={users}
              value={
                fields?.our_reference
                  ? users.find((item) => item.label === fields.our_reference)
                  : ""
              }
              disabled={blockPurchaseEdit}
            />
          </Box>
        </div>
      </div>
    );
}

export default ContactReferenceView;
