import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useParams } from "react-router-dom";
import DialogBox from "../../components/modals/DialogBox";
import AdressAndContactsInfo from "./ProjectDetailTabViews/AdressAndContacts/AdressAndContactsView";
import * as api from "../../api/project/projectApi";
import ProjectHeader from "./ProjectHeader";
import StatusView from "./ProjectDetailTabViews/Status/StatusView";
import logo from "../../assets/gifs/loading.gif";
import Content from "../../components/Content";
import { useQueryClient } from "react-query";
import DocumentOverView from "./documents/DocumentOverView";
import ProductionView from "./ProjectDetailTabViews/Production/ProductionView";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCallbackPrompt } from "../../hooks/useCallBackPrompt";
import ProjectPurchaseOverview from "./projectdetails/ProjectPurchaseOverview";
import ProjectModuleOverview from "./projectdetails/ProjectModuleOverview";
import ProjectErrandOverView from "./projectdetails/ProjectErrandOverView";
import ProjectContactPersonOverview from "./projectdetails/ProjectContactPersonOverview";
import ProjectLogOverview from "./projectdetails/ProjectLogOverview";
import EconomyOverview from "./ProjectDetailTabViews/Economy/EconomyOverview";
function ProjectDetails() {
  const [unsavedDataStatusView, setUnsavedDataStatusView] = useState(false);
  const [unSavedDataAdressContactView, setUnsavedDataAdressContactView] =
    useState(false);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    unsavedDataStatusView || unSavedDataAdressContactView
  );

  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [project, setProject] = useState();
  const [lastTab, setLastTab] = useState(1);
  const { auth } = useAuthContext();

  useEffect(() => {
    api.getProject(projectId).then((project) => setProject(project));

    setUnsavedDataStatusView(false);
    setUnsavedDataAdressContactView(false);
  }, [projectId]);

  useEffect(() => {
    let lastTab = JSON.parse(localStorage.getItem("projectdetails"));
    if (lastTab) {
      setLastTab(lastTab);
    }
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [queryClient]);

  const tabs = [
    { label: "Översikt", id: 1 },
    { label: "Logg", id: 2 },
    { label: "Ärenden", id: 3 },
    { label: "Kontaktpersoner", id: 4 },
    { label: "Dokument", id: 5 },
    { label: "Ekonomi", id: 6 },
    { label: "Inköp", id: 7 },
    { label: "Produktion", id: 8 },
    { label: "Modul", id: 9 },
    { label: "Statuskoll", id: 10 },
  ];

  if (typeof project === "object") {
    return (
      <>
        <DialogBox
          message={` ${
            unsavedDataStatusView ? "Du har osparad data på Statuskollen " : ""
          }   ${
            unSavedDataAdressContactView
              ? "\n Du har osparad data på Översikt"
              : ""
          }  `}
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Content
          tabsCointainerId={"projectdetailstabs"}
          tabs={true}
          tabList={tabs}
          title={
            <ProjectHeader
              project={project}
              preventPageChange={unSavedDataAdressContactView}
            />
          }
          lastTab={lastTab}
          view="projectdetails"
        >
          <div value={1}>
            <AdressAndContactsInfo
              project={project}
              setPreventPageChange={setUnsavedDataAdressContactView}
              preventPageChange={unSavedDataAdressContactView}
            />
          </div>
          <div value={2}>
            <ProjectLogOverview projectId={project.id} />
          </div>
          <div value={3}>
            <ProjectErrandOverView project={project} user={auth.user} />
          </div>
          <div value={4}>
            <ProjectContactPersonOverview project={project} />
          </div>
          <div value={5}>
            <DocumentOverView project={project} />
          </div>
          <div value={6}>
            <EconomyOverview
              projectId={project.id}
              projectNumber={project.project_project_number}
            />
          </div>
          <div value={7}>
            <ProjectPurchaseOverview projectId={project.id} />
          </div>
          <div value={8}>
            <ProductionView projectId={projectId} />
          </div>
          <div value={9}>
            <ProjectModuleOverview
              projectId={project.id}
              projectNumber={project.project_project_number}
            />
          </div>
          <div value={10}>
            <StatusView
              projectId={project.id}
              clientDeliveryDate={project.project_client_delivery}
              factoryDeliveryDate={project.project_factory_delivery}
              preventPageChange={unsavedDataStatusView}
              setPreventPageChange={setUnsavedDataStatusView}
            />
          </div>
        </Content>
      </>
    );
  } else {
    return (
      <div style={{ position: "absolute", left: "45%", top: "40%" }}>
        <img
          src={logo}
          alt="loading..."
          style={{ height: "200px", width: "200px" }}
        />
      </div>
    );
  }
}

export default ProjectDetails;
